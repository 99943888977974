<template>
  <v-main>

    <v-container
        id="add-user-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-logout"
          class="px-5 py-3"
          ref="form"
      >
        <v-alert color="success"
                 dark
                 outlined>
          <p>You have logged out of your account successfully.</p>
        </v-alert>

        </base-material-card>

    </v-container>
  </v-main>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "CoreLogoutView",
  components: {
  },
  created() {
    this.setAuthorization(null)
    this.setIsLoggedIn()
  },
  methods: {
    ...mapMutations({
      setAuthorization: 'setAuthorization',
      setIsLoggedIn: 'setIsLoggedIn'
    }),
  },
  data() {
    return {}
  }
};

</script>
